import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { environment, initialize } from './environments/environment';
import './index.css';

const LazyApp = React.lazy(() => import('./views/App'));

export const init = async () => {
  try {
    await initialize();
  } catch (e) {
    console.error(e);
    const container = document.getElementById('root');
    container!.innerHTML =
      '<h1 class="error" style="margin: auto">Could not load the application config</h1>';
    throw e;
  }

  if (environment.faroEndpoint) {
    initializeFaro({
      url: environment.faroEndpoint,
      app: {
        name: 'quantum',
        version: environment.version,
        environment: environment.name,
      },
      instrumentations: [
        // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
        ...getWebInstrumentations({
          captureConsole: true,
          captureConsoleDisabledLevels: [],
        }),

        // Initialization of the tracing package.
        // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
        new TracingInstrumentation(),
      ],
    });
  }

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <LazyApp />
    </React.StrictMode>,
  );
};
init();
